@import tools
@import data-table

.unpaid-ar
    // max-width: 1000px
    .rdt_TableHeader
        text-align: center

    .rdt_Table
        width: 95vw
        max-width: 1000px
        // min-height: 75vh
