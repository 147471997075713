@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+Display:wght@300;400;500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Gabarito:wght@400;500;600;700&display=swap");
:root {
  --primary-base: #6eba4e;
  --primary-darken: rgb(0, 91, 114);
  --primary-darkest: rgb(0,61,76);
  --primary-lighter: #6dd849;
  --brand-text-color: #63ab4a;
  --brand-text-color-light: #a0c295;
  --brand-text-hover: lighten(var(--brand-text-color), 20%);
  --border-color: #3e4c5a;
  --gradient-color-1: var(--primary-darkest);
  --gradient-color-2: rgba(66,142,75,1);
  --gradient-color-3: rgb(73, 193, 84);
  font-family: Lato, Helvetica, sans-serif;
  --font-family: Lato, Helvetica, sans-serif;
  --surface-a: #fefefe;
  --surface-b: #6cc24a;
  --surface-c: #003d4c;
  --surface-d: #001516;
  --surface-e: #ffffff;
  --surface-f: #f2682a;
  --text-color: rgb(4, 61, 117);
  --text-color-secondary: #708da9;
  --text-color-light-primary: #7daedb;
  --primary-color: #efefef;
  --primary-color-text: #ffffff;
  --surface-0: #ffffff;
  --surface-50: #f1f4f6;
  --surface-100: #e3eee5;
  --surface-200: #dbe6dd;
  --surface-300: #c9dfcd;
  --surface-400: #b0d4b9;
  --surface-500: #80b88e;
  --surface-600: #75a380;
  --surface-700: #435565;
  --surface-800: #2d3844;
  --surface-900: #161c22;
  --gray-50: #f1f4f6;
  --gray-100: #e2e8ee;
  --gray-200: #c6d1dd;
  --gray-300: #a9bbcb;
  --gray-400: #8da4ba;
  --gray-500: #708da9;
  --gray-600: #5a7187;
  --gray-700: #435565;
  --gray-800: #2d3844;
  --gray-900: #161c22;
  --green-500: #6dd849;
  --content-padding: 1.25rem;
  --inline-spacing: 0.5rem;
  --border-radius: 6px;
  --surface-ground: #eff3f8;
  --surface-section: #ffffff;
  --surface-card: #ffffff;
  --surface-overlay: #ffffff;
  --surface-border: #dfe7ef;
  --surface-hover: #f6f9fc;
  --focus-ring: 0 0 0 1px #c6b9fd;
  --maskbg: rgba(0, 0, 0, 0.4);
  --highlight-bg: #dfdcfc;
  --highlight-text-color: #7254f3;
  --primary-text-font: "Noto Sans Display", sans-serif;
  --primary-header-font: "Gabarito", sans-serif;
}

:root {
  --content-width: 1600px;
  --change-point: 1200px;
  --dialog-max: 1000px;
  --border-style-color: var(--border-color);
  --subtle-shadow: 1px 1px 2px 1px #2d332b1f;
  --subtle-text-shadow: #2d332b1c 2px 1px 2px;
}

@media (min-width: 1200px) {
  #landing-page {
    max-width: var(--content-width);
    margin: auto;
    box-shadow: 0px 1px 19px 5px rgba(91, 100, 89, 0.2039215686);
  }
}
#landing-page .p-datatable {
  margin: auto;
}
#landing-page .p-column-title {
  font-size: calc(1rem + 0.45vw);
  font-weight: 700;
  font-family: var(--primary-header-font);
}
#landing-page .data-table-text {
  font-size: calc(0.6rem + 0.5vw);
  font-weight: 400;
  font-family: var(--primary-text-font);
}

.main-header {
  font-size: calc(1.15rem + 0.5vw);
  font-family: var(--primary-header-font);
  font-weight: 700;
}

#email {
  margin-top: auto;
  height: auto;
}

.price-guide-button {
  padding: 3px;
}

.p-button {
  box-shadow: var(--subtle-shadow);
}

.subtle-shadow-text {
  text-shadow: var(--subtle-text-shadow);
}

.loader {
  position: absolute;
  z-index: 9999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgb(3, 59, 11);
  background: radial-gradient(circle, rgba(46, 57, 94, 0.866) 0%, rgba(59, 71, 97, 0.46) 49%, rgba(0, 0, 0, 0.1488970588) 100%);
}

.message {
  position: fixed;
  z-index: 9999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: white;
  text-align: center;
}

#price-table {
  margin: 5px 0;
  border: 2px solid var(--border-style-color);
}
#price-table .job-text-cell {
  font-size: calc(0.65rem + 0.45vw);
  font-weight: 600;
  font-family: var(--primary-text-font);
  margin: 0;
  padding: 0 0 0 2%;
}
#price-table .job-text {
  font-family: var(--primary-header-font);
  font-size: calc(0.85rem + 0.25vw);
  margin: 0;
  padding: 0;
  font-weight: 600;
  border-right: 2px solid var(--border-style-color);
}
#price-table .job-info-text {
  font-weight: 400;
  font-family: var(--primary-text-font);
  font-size: calc(0.75rem + 0.25vw);
}
#price-table .row-legend {
  font-size: calc(0.75rem + 0.25vw);
  font-weight: 600;
  font-family: var(--primary-header-font);
  align-items: center;
  padding: 1% 0;
}
#price-table .row-legend div {
  padding: 2%;
}
#price-table .cell-info {
  font-weight: 300;
  font-size: 0.85rem;
}
#price-table .price-table-row:nth-child(even) {
  background: var(--surface-200);
  filter: brightness(110%);
}
#price-table .price-table-row:nth-child(odd) {
  background: var(--surface-300);
  filter: brightness(110%);
}
#price-table .price-table-row {
  margin: auto;
  border-bottom: 2px solid var(--border-style-color);
}
#price-table .price-table-row .price-row-cell {
  padding: 0.35rem 0 0 0.5rem;
  font-size: calc(0.65rem + 0.45vw);
  font-family: var(--primary-text-font);
}
#price-table .price-table-row .value-cell .upper-cell {
  border-bottom: 1px solid var(--border-style-color);
}
#price-table .price-table-row:last-child {
  border-bottom: 0;
}

#price-table-header {
  font-size: calc(0.95rem + 0.2vw);
  font-weight: 700;
  font-family: var(--primary-header-font);
  margin: auto;
  font-weight: 600;
  color: var(--brand-text-color-light);
  background: var(--primary-darken);
}

#ncto {
  margin: auto;
}
@media (min-width: 1200px) {
  #ncto {
    max-width: var(--content-width);
    box-shadow: 0px 1px 19px 5px rgba(91, 100, 89, 0.2039215686);
  }
}
#ncto .ncto-content-header {
  font-size: calc(1.15rem + 0.5vw);
  font-weight: 700;
  font-family: var(--primary-header-font);
}
#ncto .ncto-panel-title {
  font-size: calc(1rem + 0.25vw);
  font-weight: 700;
  font-family: var(--primary-header-font);
}
#ncto .p-panel h6 {
  font-size: calc(0.85rem + 0.25vw);
  font-family: var(--primary-text-font);
}
#ncto .p-button-label {
  font-size: calc(0.65rem + 0.45vw);
  font-family: var(--primary-text-font);
}
#ncto .p-inputtext {
  font-size: calc(0.65rem + 0.45vw);
  font-family: var(--primary-text-font);
}
#ncto .p-panel-header {
  background: var(--surface-400);
  background: linear-gradient(45deg, var(--surface-500) 0%, var(--surface-400) 100%);
}
#ncto .p-panel-title {
  color: var(--text-color);
}
#ncto .p-inputwrapper-focus {
  background: var(--surface-400);
}
#ncto .p-autocomplete.p-button {
  background: var(--primary-darken);
}
#ncto .p-autocomplete-item {
  max-height: 25px;
}
#ncto .row-item:nth-child(even) {
  background: var(--surface-200);
}
#ncto .row-item:nth-child(odd) {
  background: var(--surface-300);
}
#ncto .job-select-card {
  background: var(--surface-100);
}

#job-selection #calendar {
  width: 17%;
}
#job-selection #estimate {
  width: 16%;
}
#job-selection .job-name {
  line-height: 1;
}

.job-field-label {
  font-size: calc(0.75rem + 0.25vw);
  font-family: var(--primary-text-font);
}

.job-field-unselected {
  background-color: rgba(230, 230, 230, 0.8156862745);
  margin: 3px 0;
  padding: 0.3rem 5px 0;
  min-height: 2.6rem;
  border: 3px solid #d3d2d2;
  border-radius: 3px;
  font-size: calc(0.5rem + 0.5vw);
  font-family: var(--primary-text-font);
  cursor: not-allowed;
  color: #838383;
}
.job-field-unselected .pi {
  font-size: 16px !important;
}
.job-field-unselected .sim-button {
  float: right;
  margin: auto;
  font-size: calc(0.7rem + 0.5vw);
}
.job-field-unselected .job-field-unselected-label {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.job-field-unselected:hover {
  background-color: rgba(245, 245, 245, 0.8156862745);
}

.job-field-selected .p-inputtext {
  font-size: calc(0.25vw + 0.75rem) !important;
  font-family: var(--primary-text-font) !important;
}

.job-select-column-names {
  font-size: calc(1rem + 0.15vw);
  font-family: var(--primary-header-font);
  font-weight: 700;
}

.action-buttons {
  margin: 0 3px;
}
.action-buttons.p-button-icon-only {
  padding: 0.2rem 0;
}

.mx-width-pane {
  max-width: 1000px;
}

.small-info-text {
  font-weight: 400;
  font-size: 1rem;
}

.clear-borders {
  border: none;
}

.field-error-display {
  border-radius: 3px;
  padding: 3px 7px 4px 10px;
  font-weight: bold;
  color: #a0181f;
  font-kerning: none;
  word-spacing: -4px;
}

.review-text-lg {
  font-size: calc(0.85rem + 0.25vw);
}

.review-text-label {
  font-size: calc(0.75rem + 0.25vw);
}

.review-job-row-header {
  border-bottom: 2px solid var(--text-color);
  font-size: 1.2rem;
  font-weight: 500;
}

.review-content {
  box-shadow: 0px 1px 19px 5px rgba(91, 100, 89, 0.2039215686);
}

.review-popup-header {
  font-size: calc(0.8rem + 0.65vw);
  font-weight: 700;
  font-family: var(--primary-header-font);
}
.review-popup-header h6 {
  display: inline;
  margin-left: 5px;
  font-size: calc(0.65rem + 0.5vw);
}

.review-page-header {
  background-color: var(--surface-100) !important;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  height: auto;
  padding: 5px !important;
  font-weight: 700;
  font-family: var(--primary-header-font);
  font-size: calc(0.8rem + 0.65vw);
}

.review-job-row:nth-child(even) {
  background: var(--surface-200);
}

.review-job-row:nth-child(odd) {
  background: var(--surface-50);
}

.review-unstyled-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.p-tooltip .p-tooltip-text {
  box-shadow: none !important;
}/*# sourceMappingURL=ncto.css.map */